import { StyleSheet, Platform } from 'react-native'

export const CELL_SIZE = 55
export const CELL_BORDER_RADIUS = 8
export const DEFAULT_CELL_BG_COLOR = '#fff'
export const NOT_EMPTY_CELL_BG_COLOR = '#3557b7'
export const ACTIVE_CELL_BG_COLOR = '#f7fafe'

const verifystyle = StyleSheet.create({
  codeFiledRoot: {
    height: CELL_SIZE,
    marginTop: 30,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  cell: {
    marginHorizontal: 8,
    height: CELL_SIZE,
    width: CELL_SIZE - 20,
    lineHeight: CELL_SIZE - 5,
    fontSize: 30,
    textAlign: 'center',
    borderRadius: CELL_BORDER_RADIUS,
    color: '#3759b8',
    backgroundColor: '#fff',

    boxShadow: '0 1px 2.22px rgba(0, 0, 0, 0.22)',

    // Android
    elevation: 3,
  },

  // =======================

  root: {
    minHeight: 40,
    padding: 20,
  },
  title: {
    paddingTop: 50,
    color: '#000',
    fontSize: 25,
    fontWeight: '700',
    textAlign: 'center',
    paddingBottom: 40,
  },
  icon: {
    width: 217 / 2.4,
    height: 158 / 2.4,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subTitle: {
    paddingTop: 30,
    color: '#000',
    textAlign: 'center',
  },
  nextButton: {
    marginTop: 30,
    borderRadius: 60,
    height: 60,
    backgroundColor: '#3557b7',
    justifyContent: 'center',
    minWidth: 300,
    marginBottom: 100,
  },
  nextButtonText: {
    textAlign: 'center',
    fontSize: 20,
    color: '#fff',
    fontWeight: '700',
  },
})

export default verifystyle
