import React, { useEffect, useRef } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { Text } from 'react-native-paper'
import { Video } from 'expo-av'
import { theme } from '../core/theme'
import Background from '../components/Background'
import Button from '../components/Button'
import { setTopLevelNavigator, store } from '../store'
import { GET_REWARDS } from '../store/actions'
import { createAttend, updateAttend } from '../services/apis/server'

export default function Welcome({ navigation }) {
  useEffect(() => {
    setTopLevelNavigator(navigation)
  }, [])

  const dispatch = useDispatch()
  const video = React.useRef(null)
  const state = store.getState()
  const [status, setStatus] = React.useState({})
  const [play, setPlay] = React.useState(false)
  const allEvents = state.campaign.events
  const today = new Date()
  const params = new URLSearchParams(window.location.search)
  const eventid = params.get('event_id')
  const top_score = params.get('top_score')
  const play_flag = true
  const futureEvent = allEvents.filter(
    (item) => Number(item.id) === Number(eventid)
  )
  const token = useSelector(() => state.auth)
  const decoded = jwt_decode(token.token)
  let duration = 0
  let video_url = ''
  let game_url = ''
  if (futureEvent.length > 0) {
    duration = Math.floor(
      (new Date(futureEvent[0].start_time).getTime() - today.getTime()) / (1000 * 60)
    )
    video_url = futureEvent[0].sponsor_video_url
  }

  game_url = `https://play.zoomingaming.com/?event_id=${encodeURIComponent(eventid)}&user_id=${encodeURIComponent(decoded.id)}&top_score=${encodeURIComponent(top_score)}`

  // const openGameUrl = async () => {
  //   try {
  //     const supported = await Linking.canOpenURL(game_url)
  //     if (supported) {
  //       await Linking.openURL(game_url)
  //     } else {
  //       console.error("Don't know how to open this URL:", game_url)
  //     }
  //   } catch (err) {
  //     console.error('Failed to open URL:', err)
  //   }
  // }

  const onGameJoin = async () => {
    dispatch({ type: GET_REWARDS, rewards: futureEvent[0].rewards })
    try {
      const attend = await createAttend({
        user_id: Number(decoded.id),
        event_id: Number(eventid),
        score: 0,
      })
    } catch (e) {
      try {
        const attend = await updateAttend(
          {
            user_id: Number(decoded.id),
            event_id: Number(eventid),
            score: 0,
          },
          eventid,
          decoded.id
        )
      } catch (err) {
        console.log(err)
      }
      console.log(e)
    }
  }

  const linkRef = useRef(null) // Ref for the <a> tag

  const handleButtonPress = () => {
    if (linkRef.current) {
      linkRef.current.click() // Simulate the click on <a> when the button is clicked
    }
  }

  return (
    <Background>
      <Image source={require('../assets/welcome2.png')} style={styles.image} />
      <Text style={styles.username}>{decoded.name}</Text>
      <Text style={styles.ad}>Game Starts In {duration} minutes</Text>
      <View style={styles.container}>
        <Video
          ref={video}
          style={styles.video}
          source={{
            uri: video_url,
          }}
          shouldPlay
          useNativeControls
          resizeMode="contain"
          onPlaybackStatusUpdate={(s) =>
            setStatus(() => {
              if (!s.isPlaying && play) {
                if (!play_flag) {
                  setPlay(true)
                }
              } else {
                setTimeout(() => {
                  setPlay(true)
                }, 3000)
              }
            })
          }
        />
      </View>

      {/* Hidden <a> tag with ref */}
      <a
        ref={linkRef}
        href={game_url}
        style={{ display: 'none' }} // Hide the <a> tag
        onClick={onGameJoin}
      ></a>

      {/* Button triggers the <a> tag click */}
      <Button mode="contained" style={{ marginTop: 16 }} onPress={handleButtonPress}>
        Join Now
      </Button>

      <Text style={styles.footer}>Get warmed up while you wait!</Text>
    </Background>
  )
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: 40,
    margin: 5,
  },
  username: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 28,
    lineHeight: 40,
    color: theme.colors.white,
  },
  ad: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 24,
    lineHeight: 40,
    color: theme.colors.white,
  },
  footer: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 40,
    color: theme.colors.white,
  },
  video: {
    width: 300,
    height: 300,
  },
})
